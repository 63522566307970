<template>
    <div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll; background-color: white;">
        <SelectProjectType
            v-if="instance.projectType === ''"
            @selectType="selectType"
        />
        <div v-else style="margin: 0 120px 0 120px">
            <div v-if="!successInInstanceCreation && !errorInInstanceCreation">
                <a-steps class="mb-5" :current="current">
                    <a-step
                        v-for="item in steps"
                        :key="item.title"
                        :title="item.title"
                    >
                        <span slot="description">{{ item.description }}</span>
                    </a-step>
                </a-steps>

                <!-- First Step -->
                <a-card v-if="current === 0">
                    <a-form-model
                        ref="instance"
                        :model="instance"
                        class="profile"
                    >
                        <div :class="$mq == 'sm' ? '' : 'dF'">
                            <h5 class="text-dark">
                                {{
                                    instance.projectType === "corporate"
                                        ? "Corporate"
                                        : "Project"
                                }}
                                Information
                            </h5>
                        </div>

                        <hr style="margin-left: -24px; margin-right: -24px" />

                        <div :class="$mq == 'sm' ? '' : 'dF'">
                            <div
                                style="order: 2"
                                :style="
                                    $mq == 'sm' ? 'width:100%' : 'width:25%'
                                "
                            >
                                <div
                                    @click="colorModal = true"
                                    class="mt-5"
                                    style="
                                        position: relative;
                                        width: 120px;
                                        height: 120px;
                                        display: block;
                                        margin: auto;
                                    "
                                >
                                    <img
                                        src="@/assets/edit-icon.svg"
                                        class="edit-current-image"
                                        alt="edit-current-image"
                                    />
                                    <div class="hexagon hexagon2">
                                        <div class="hexagon-in1">
                                            <div
                                                class="hexagon-in2"
                                                :style="{
                                                    'background-color':
                                                        instance.avatar,
                                                }"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                :class="$mq == 'sm' ? 'mt-3' : ''"
                                :style="
                                    $mq == 'sm' ? 'width:100%' : 'width:75%'
                                "
                            >
                                <a-row :gutter="16">
                                    <a-col :span="$mq == 'sm' ? 24 : 12">
                                        <a-form-model-item
                                            label="Project Owner"
											prop="userId"
											:rules="
                                                req(
                                                    'Please choose a Project Owner'
                                                )
                                            "
                                            required
                                        >
											<a-select
                                                show-search
                                                size="large"
                                                option-label-prop="label"
												:filterOption="false"
                                                v-model="instance.userId"
												@search="handleProjectSearch"
                                            >
												<a-select-option
													v-for="user in projectUserSearchResult"
													:key="user.value"
													:label="user.label"
													:value="user.value"
												>
													<div class="dF aC">
														<div class="f1">
															<div
																style="
																	color: black;
																	font-size: 15px;
																"
															>
																{{ user.label }}
															</div>
															<small>{{
																`<${user.email}>`
															}}</small>
														</div>
													</div>
												</a-select-option>
											</a-select>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                                <div
                                    v-if="instance.projectType === 'corporate'"
                                >
                                    <a-row :gutter="16">
                                        <a-col :span="$mq == 'sm' ? 24 : 8">
											<a-form-model-item
                                                label="Project Name"
                                                required
                                                prop="name"
                                                :rules="
                                                    req(
                                                        'Please enter the Name for your instance'
                                                    )
                                                "
                                            >
                                                <a-input
                                                    v-model="instance.name"
                                                    placeholder="Project Name"
                                                    size="large"
                                                    required
                                                >
                                                </a-input>
                                            </a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 8">
											<a-form-model-item
                                                prop="marketingName"
                                            >
												<template slot="label">
													Project Marketing Name
													<a-tooltip overlayClassName="change-tooltip-color" title="Fill in this field if you need a forward-facing name that end users will see for your project.">
														<a-icon type="question-circle" style="font-size: 14px" />
													</a-tooltip>
												</template>
                                                <a-input
                                                    v-model="instance.marketingName"
                                                    placeholder="Project Marketing Name"
                                                    size="large"
                                                >
                                                </a-input>
                                            </a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 8">
											<a-form-model-item
												label="Company name"
												required
												prop="companyName"
												:rules="
													req(
														'Please enter the Name of your Company'
													)
												"
											>
												<a-input
													v-model="instance.companyName"
													placeholder="Company Name"
													size="large"
													required
												>
												</a-input>
											</a-form-model-item>
										</a-col>
									</a-row>



                                    <a-form-model-item
                                        label="Solo Projects that you want to link to your corporate project"
                                        prop="soloCorporateProject"
                                    >
                                        <a-select
                                            mode="tags"
                                            size="large"
                                            class="w-full"
                                            placeholder="Please select"
                                            v-model="children"
                                        >
                                            <a-select-option
                                                v-for="(
                                                    inst, instI
                                                ) in instances"
                                                :key="
                                                    (instI + 9).toString(36) +
                                                    instI
                                                "
                                                :value="inst.instance.id"
                                            >
                                                {{ inst.instance.name }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                </div>
                                <div v-else>
                                    <a-row :gutter="16">
                                        <a-col :span="$mq == 'sm' ? 24 : 8">
                                            <a-form-model-item
                                                label="Project Name"
                                                required
                                                prop="name"
                                                :rules="
                                                    req(
                                                        'Please enter the Name for your instance'
                                                    )
                                                "
                                            >
                                                <a-input
                                                    v-model="instance.name"
                                                    placeholder="Project Name"
                                                    size="large"
                                                    required
                                                >
                                                </a-input>
                                            </a-form-model-item>
                                        </a-col>
										<a-col :span="$mq == 'sm' ? 24 : 8">
											<a-form-model-item
                                                prop="marketingName"
                                            >
												<template slot="label">
													Project Marketing Name
													<a-tooltip overlayClassName="change-tooltip-color" title="Fill in this field if you need a forward-facing name that end users will see for your project.">
														<a-icon type="question-circle" style="font-size: 14px" />
													</a-tooltip>
												</template>
                                                <a-input
                                                    v-model="instance.marketingName"
                                                    placeholder="Project Marketing Name"
                                                    size="large"
                                                >
                                                </a-input>
                                            </a-form-model-item>
										</a-col>
                                        <a-col :span="$mq == 'sm' ? 24 : 8">
                                            <a-form-model-item
                                                label="Product Type"
                                                required
                                                prop="productType"
                                                :rules="
                                                    req(
                                                        'Please choose a Product Type for your instance'
                                                    )
                                                "
                                            >
                                                <a-select
                                                    v-model="
                                                        instance.productType
                                                    "
                                                    size="large"
                                                    :disabled="
                                                        instance.projectType ===
                                                        'group'
                                                    "
                                                >
                                                    <a-select-option
                                                        value="lowrise"
                                                    >
                                                        Lowrise
                                                    </a-select-option>
                                                    <a-select-option
                                                        value="highrise"
                                                    >
                                                        Highrise
                                                    </a-select-option>
                                                </a-select>
                                            </a-form-model-item>
                                        </a-col>
                                    </a-row>

                                    <a-row
                                        v-if="instance.projectType === 'solo'"
                                        :gutter="16"
                                    >
                                        <a-col
                                            v-if="
                                                instance.productType ==
                                                'lowrise'
                                            "
                                            :span="$mq == 'sm' ? 24 : 12"
                                        >
                                            <a-form-model-item
                                                label="How many lots in your project"
                                                required
                                                prop="lots"
                                                :rules="
                                                    req(
                                                        'Please enter the number of lots in your project'
                                                    )
                                                "
                                            >
                                                <a-input
                                                    v-model.number="
                                                        instance.lots
                                                    "
                                                    placeholder="Number of lots"
                                                    size="large"
                                                    required
                                                    type="number"
                                                    :min="0"
                                                >
                                                </a-input>
                                            </a-form-model-item>
                                        </a-col>
                                        <a-col
                                            v-if="
                                                instance.productType ==
                                                'highrise'
                                            "
                                            :span="$mq == 'sm' ? 24 : 12"
                                        >
                                            <a-form-model-item
                                                label="How many doors in your project"
                                                required
                                                prop="doors"
                                                :rules="
                                                    req(
                                                        'Please enter the number of doors in your project'
                                                    )
                                                "
                                            >
                                                <a-input
                                                    v-model.number="
                                                        instance.doors
                                                    "
                                                    placeholder="Number of doors"
                                                    size="large"
                                                    required
                                                    type="number"
                                                    :min="0"
                                                >
                                                </a-input>
                                            </a-form-model-item>
                                        </a-col>
                                        <a-col :span="$mq == 'sm' ? 24 : 12">
                                            <a-form-model-item
                                                label="Builder/Developer Name"
                                                required
                                                prop="builderName"
                                                :rules="
                                                    req(
                                                        'Please enter the Builder/Developer Name'
                                                    )
                                                "
                                            >
                                                <a-input
                                                    v-model="
                                                        instance.builderName
                                                    "
                                                    placeholder="Builder/Developer Name"
                                                    size="large"
                                                    required
                                                >
                                                </a-input>
                                            </a-form-model-item>
                                        </a-col>
                                    </a-row>
                                    <div
                                        v-if="instance.projectType === 'group'"
                                    >
                                        <a-form-model-item
                                            label="Link applicable solo projects to this group project"
                                            prop="soloGroupProject"
                                        >
                                            <a-select
                                                mode="tags"
                                                size="large"
                                                class="w-full"
                                                placeholder="Please select"
                                                v-model="children"
                                            >
                                                <a-select-option
                                                    v-for="(
                                                        inst, instI
                                                    ) in instances"
                                                    :key="
                                                        (instI + 9).toString(
                                                            36
                                                        ) + instI
                                                    "
                                                    :value="inst.instance.id"
                                                >
                                                    {{ inst.instance.name }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-model-item>
                                    </div>
                                </div>

                                <hr
                                    style="
                                        margin-left: -24px;
                                        margin-right: calc(-33.333% - 24px);
                                    "
                                />
                                <h5 class="text-dark">
                                    Billing Details
                                </h5>
                                <hr
                                    style="
                                        margin-left: -24px;
                                        margin-right: calc(-33.333% - 24px);
                                    "
                                />

								<a-row :gutter="16">
									<a-col :span="$mq == 'sm' ? 24 : 8">
                                        <a-form-model-item
                                            label="Billing Owner"
											prop="billingUserId"
											:rules="
                                                req(
                                                    'Please choose a Billing Owner'
                                                )
                                            "
                                            required
                                        >
											<a-select
                                                show-search
                                                size="large"
                                                option-label-prop="label"
												:filterOption="false"
                                                v-model="instance.billingUserId"
												@search="handleBillingSearch"
												@change="getPartners"
                                            >
												<a-select-option
													v-for="user in billingUserSearchResult"
													:key="user.value"
													:label="user.label"
													:value="user.value"
												>
													<div class="dF aC">
														<div class="f1">
															<div
																style="
																	color: black;
																	font-size: 15px;
																"
															>
																{{ user.label }}
															</div>
															<small>{{
																`<${user.email}>`
															}}</small>
														</div>
													</div>
												</a-select-option>
											</a-select>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="$mq == 'sm' ? 24 : 8">
                                        <a-form-model-item
                                            required
                                            prop="billingName"
                                            :rules="req( 'Please enter the Billing Name')"
                                        >
											<template slot="label">
												Billing Name
												<a-tooltip overlayClassName="change-tooltip-color" title="The 'Billing Name' is what will appear on the invoice.">
													<a-icon type="question-circle" style="font-size: 14px" />
												</a-tooltip>
											</template>
                                            <a-input
                                                v-model="instance.billingName"
                                                placeholder="Billing Name"
                                                size="large"
                                                required
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
									<a-col :span="$mq == 'sm' ? 24 : 8" v-if="partners.length">
                                        <a-form-model-item
                                            prop="partner"
                                        >
											<template slot="label">
												Partner
												<a-tooltip overlayClassName="change-tooltip-color" title="Partner options will be populated based on the selected Billing Owner.">
													<a-icon type="question-circle" style="font-size: 14px" />
												</a-tooltip>
											</template>
                                            <a-select
                                                show-search
                                                placeholder="Choose a partner"
                                                size="large"
                                                option-filter-prop="children"
                                                :filter-option="filterOption"
                                                v-model="instance.partner"
                                            >
                                                <a-select-option
                                                    v-for="partner in partners"
                                                    :key="partner.id"
                                                    :value="partner.id"
                                                >
                                                    {{ partner.companyName }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>

                                <a-row :gutter="16">
                                    <a-col :span="$mq == 'sm' ? 24 : 12">
                                        <a-form-model-item
                                            label="Address"
                                            prop="address"
                                            required
                                            :rules="
                                                req('Please enter the Address')
                                            "
                                        >
                                            <a-input
                                                v-model="instance.address"
                                                placeholder="Enter your location"
                                                size="large"
                                                required
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="$mq == 'sm' ? 24 : 12">
                                        <a-form-model-item
                                            label="Country"
                                            prop="country"
                                            required
                                            :rules="
                                                req('Please choose a Country')
                                            "
                                        >
                                            <a-select
                                                show-search
                                                placeholder="Choose a country"
                                                size="large"
                                                option-filter-prop="children"
                                                :filter-option="filterOption"
                                                v-model="instance.country"
                                                @change="
                                                    instance.region = null;
                                                    instance.postalCode = null;
                                                "
                                            >
                                                <a-select-option value="US">
                                                    United States
                                                </a-select-option>
                                                <a-select-option value="CA">
                                                    Canada
                                                </a-select-option>
                                            </a-select>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>

                                <a-row :gutter="16">
                                    <a-col :span="$mq == 'sm' ? 24 : 8">
                                        <a-form-model-item
                                            label="City"
                                            prop="city"
                                            required
                                            :rules="req('Please enter a City')"
                                        >
                                            <a-input
                                                placeholder="City"
                                                size="large"
                                                v-model="instance.city"
                                            />
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="$mq == 'sm' ? 24 : 8">
                                        <a-form-model-item
                                            label="State/Province"
                                            prop="region"
                                            required
                                            :rules="
                                                req(
                                                    'Please choose a State/Province'
                                                )
                                            "
                                        >
                                            <a-select
                                                show-search
                                                placeholder="Choose a state/province"
                                                size="large"
                                                option-filter-prop="children"
                                                :filter-option="filterOption"
                                                v-model="instance.region"
                                            >
                                                <template
                                                    v-if="
                                                        instance.country ===
                                                        'US'
                                                    "
                                                >
                                                    <a-select-option
                                                        v-for="(
                                                            opt, optI
                                                        ) in states"
                                                        :key="opt.name + optI"
                                                        :value="opt.value"
                                                    >
                                                        {{ opt.name }}
                                                    </a-select-option>
                                                </template>
                                                <template v-else>
                                                    <a-select-option
                                                        v-for="(
                                                            opt, optI
                                                        ) in provinces"
                                                        :key="opt.name + optI"
                                                        :value="opt.value"
                                                    >
                                                        {{ opt.name }}
                                                    </a-select-option>
                                                </template>
                                            </a-select>
                                        </a-form-model-item>
                                    </a-col>
                                    <a-col :span="$mq == 'sm' ? 24 : 8">
                                        <a-form-model-item
                                            label="Zip/Postal Code"
                                            prop="postalCode"
                                            :rules="[
                                                {
                                                    validator:
                                                        validatePostalCodes,
                                                    trigger: 'change',
                                                },
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter a postal code',
                                                },
                                            ]"
                                        >
                                            <a-input
                                                v-model="instance.postalCode"
                                                size="large"
                                                required
                                            >
                                            </a-input>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                            </div>
                        </div>
                    </a-form-model>
                    <div class="dF jE w-full">
                        <a-button-group style="margin-right: 20px">
                            <a-button
                                @click="$router.push('/projects')"
                                style="
                                    width: 100px;
                                    margin-right: 20px;
                                    background-color: #ece9f1;
                                "
                                >CANCEL</a-button
                            >
                            <a-button
                                @click="next"
                                type="primary"
                                style="width: 100px"
                                >NEXT</a-button
                            >
                        </a-button-group>
                    </div>
                </a-card>

                <!-- Second Step -->
                <div v-else-if="current === 1" style="text-align: center">
                    <div v-if="!packages.length">
                        <a-icon type="loading" style="font-size: 30px" />
                    </div>
                    <div v-else>
                        <div class="dF aC" style="gap: 24%; justify-content: right">
							<a-radio-group v-model="recurrence" size="large">
								<a-radio-button
									value="monthly"
									style="height: auto"
								>
									<span>
										<b>Pay Monthly</b><br />Commit annually
									</span>
								</a-radio-button>
								<a-radio-button value="yearly" style="height: auto">
									<span>
										<b
											>Pay Annually<span class="text-primary">
												SAVE 10%
											</span> </b
										><br />Commit annually
									</span>
								</a-radio-button>
							</a-radio-group>

							<a-select
								v-model="instance.currency"
								:default-value="{ key: 'USD' }"
								style="width: 120px"
								@change="onCurrencyChange"
							>
								<a-select-option value="USD">
								$ USD
								</a-select-option>
								<a-select-option value="CAD">
								$ CAD
								</a-select-option>
							</a-select>
						</div>

                        <div class="grid-display mt-8" :style="packageStyle">
                            <div
                                class="type-card"
                                v-for="(p, index) in packages"
                                :key="index"
                                @click="selectedPackageId = p.id"
								style="padding: 30px"
                                :class="{
                                    'selected-package':
                                        p.id === selectedPackageId,
                                }"
                            >
                                <div
                                    class="text-center"
                                    style="
                                        color: #000;
                                        font-size: 20px;
                                        margin-top: 10px;
                                        min-height: 60px;
                                    "
                                >
                                    {{ p.name.toUpperCase() }}
                                </div>
                                <a-divider />
                                <div class="text-center">
                                    <div style="font-size: 30px; color: #000">
                                        <span v-if="recurrence === 'monthly'"
                                            >${{ (p.price).toFixed(2) }}/mo {{instance.currency === 'CAD' ? '(CAD)' : ''}}</span
                                        >
                                        <span v-else>
                                            ${{
                                                (p.price -
                                                (p.price * 0.1)).toFixed(2)
                                            }}/mo {{instance.currency === 'CAD' ? '(CAD)' : ''}}
                                        </span>
                                    </div>
                                    <div
                                        style="font-size: 20px; color: #000"
                                        v-if="recurrence === 'yearly'"
                                    >
                                        billed at
                                        <span
                                            style="
                                                text-decoration: line-through;
                                            "
                                        >
                                            ${{ (p.price * 12).toFixed(2) }} {{instance.currency === 'CAD' ? '(CAD)' : ''}}
                                        </span>
                                        &nbsp;
                                        <strong>
                                            ${{
                                                ((p.price * 12) - (p.price * 12 * 0.1)).toFixed(2)
                                            }}/yr {{instance.currency === 'CAD' ? '(CAD)' : ''}}</strong
                                        >
                                    </div>
                                    <div
                                        class="mt-3"
                                        :style="{
                                            'min-height':
                                                instance.projectType ===
                                                    'solo' &&
                                                instance.productType ===
                                                    'lowrise'
                                                    ? '160px'
                                                    : 'fit-content',
                                        }"
                                    >
                                        {{ p.frontCopy }}
                                    </div>
                                </div>
                                <a-divider />
                                <div
                                    v-if="addOnsPackages.length"
                                    style="text-align: left"
                                >
									<strong
                                        style="
                                            font-size: 18px;
                                        "
                                        >Add Ons
                                    </strong>
                                    <div
                                        v-for="(
                                            addOn, addOnIndex
                                        ) in addOnsPackages"
                                        :key="p.id + addOnIndex"
                                        class="py-2"
                                    >
                                        <a-checkbox
                                            @change="
                                                onCheckboxChange(
                                                    $event,
                                                    addOn,
                                                    p.id
                                                )
                                            "
                                        >
                                            <strong>
												{{ addOn.name }}
												<a-tooltip v-if="addOn.description" overlayClassName="change-tooltip-color" >
			                                        <template slot="title">
			                                            {{addOn.description}}
			                                        </template>
			                                        <a-icon
			                                            type="question-circle"
			                                            style="font-size: 14px"
			                                        />
			                                    </a-tooltip>
											</strong><span v-if="addOn.subtitle"> - </span>
                                            {{ addOn.subtitle }}
                                        </a-checkbox>
                                    </div>
                                </div>

                                <div
                                    v-if="oneTimePackages.length"
                                    style="text-align: left"
                                >
                                    <div
                                        v-for="(
                                            oneTime, oneTimeIndex
                                        ) in oneTimePackages"
                                        :key="p.id + oneTimeIndex"
                                        class="py-2"
                                    >
                                        <a-checkbox
                                            @change="
                                                onCheckboxChange(
                                                    $event,
                                                    oneTime,
                                                    p.id
                                                )
                                            "
                                            :disabled="
                                                oneTime.identifier ===
                                                    'onboarding' &&
                                                ownedInstanceCount === 0
                                            "
                                            :default-checked="
                                                (oneTime.identifier ===
                                                    'onboarding' &&
                                                    ownedInstanceCount === 0) ||
                                                false
                                            "
                                        >
                                            <strong>{{ oneTime.name }}</strong>
                                            -
                                            {{ oneTime.subtitle }}
                                        </a-checkbox>
                                    </div>
                                </div>
                                <div class="mt-3 text-center">
                                    <a
                                        :href="websiteLink"
                                        target="”_blank”"
                                        style="
                                            color: var(--orange);
                                            font-size: 16px;
                                        "
                                        >LEARN MORE</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <a-button
                                @click="showAllFeatures = !showAllFeatures"
                            >
                                <a-icon
                                    v-if="!showAllFeatures"
                                    type="down"
                                    style=""
                                />
                                <a-icon v-if="showAllFeatures" type="up" />
                                SHOW ALL FEATURES
                            </a-button>

                            <div
                                v-if="showAllFeatures"
                                class="grid-display mt-5"
                                :style="packageStyle"
                            >
                                <div
                                    class="type-card"
                                    v-for="p in packages"
                                    :key="p.id"
                                    style="
                                        padding: 10px 20px 20px 30px;
                                        text-align: left;
                                    "
                                >
                                    <AllFeaturesList :package="p.name" :packageCode="p.itemCode"/>
                                </div>
                            </div>
                        </div>
                        <div class="dF jE w-full mt-5">
                            <a-button-group>
                                <a-button
                                    @click="prev"
                                    style="
                                        width: 100px;
                                        margin-right: 20px;
                                        background-color: #ece9f1;
                                    "
                                    >BACK</a-button
                                >
                                <a-button
                                    :disabled="!selectedPackageId"
                                    @click="next"
                                    type="primary"
                                    style="width: 100px"
                                    >NEXT</a-button
                                >
                            </a-button-group>
                        </div>
                    </div>
                </div>
                <!-- Third Step -->
                <a-card v-else-if="current === 2">
                    <div :class="$mq == 'sm' ? '' : 'dF'">
                        <h5><strong>How would you like to pay?</strong></h5>
                    </div>
                    <hr style="margin-left: -24px; margin-right: -24px" />
                    <div class="profile">
                        <a-row v-if="$mq != 'sm'">
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <b
                                    >Package options
                                    <span
                                        class="text-primary ml-5"
                                        @click="current = 1"
                                        style="cursor: pointer"
                                    >
                                        Change
                                    </span>
                                </b>
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <b>Price ({{instancePriceDetails.currency}})</b>
                            </a-col>
                            <a-col
                                :span="$mq == 'sm' ? 4 : 6"
                                style="text-align: right"
                            >
                                <b>Subtotal ({{instancePriceDetails.currency}})</b>
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 2 : 1" />
                        </a-row>
                        <hr
                            v-if="$mq != 'sm'"
                            style="margin-left: -24px; margin-right: -24px"
                        />
                        <a-row v-if="$mq != 'sm'">
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <div class="text-primary">RECURRING FEES</div>
                                {{ instancePriceDetails.package && instancePriceDetails.package.name }}
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <b>
                                    ${{ instancePriceDetails.package && instancePriceDetails.package.price && instancePriceDetails.package.price.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})
                                    }}{{
                                        instancePriceDetails.package && instancePriceDetails.package
                                            .recurrence === "yearly"
                                            ? "/year"
                                            : "/month"
                                    }}
                                </b>
                                <div>
                                    (billed
                                    {{
                                        instancePriceDetails.package && instancePriceDetails.package.recurrence
                                    }})
                                </div>
                            </a-col>
                            <a-col
                                :span="$mq == 'sm' ? 4 : 6"
                                style="text-align: right"
                                class="text-black"
                            >
                                <b>${{ instancePriceDetails.package && instancePriceDetails.package.price && instancePriceDetails.package.price.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) }}</b>
                            </a-col>
                        </a-row>
                        <div v-if="$mq != 'sm'">
                            <a-row
                                v-for="(
                                    additional, index
                                ) in instancePriceDetails.additional"
                                :key="index"
                                class="mt-3"
                            >
                                <a-col :span="$mq == 'sm' ? 6 : 8">
                                    <span
                                        v-if="additional.name === 'Application Programming Interface (API Calls)' || additional.name === 'API Calls' || additional.name === 'Online Video Chat Support'"
                                    >
                                        <div class="text-primary">
											API FEES
                                        </div>
                                        {{ additional.name }}
                                    </span>
                                    <span
                                        v-if="additional.name === 'Onboarding'"
                                    >
                                        <div class="text-primary">
                                            ONE TIME FEES
                                        </div>
                                        {{ additional.name }}
                                    </span>
                                    <span
                                        v-if="
                                            additional.name === 'Lot/Door Fee'
                                        "
                                    >
                                        <div class="text-primary">
                                            <a-popover placement="right">
                                                <template slot="title">
                                                    <h3
                                                        class="
                                                            text-primary
                                                            text-center
                                                        "
                                                    >
                                                        {{
                                                            instance.productType ==
                                                            "highrise"
                                                                ? "DOOR FEES"
                                                                : "LOT FEES"
                                                        }}
                                                    </h3>
                                                </template>
                                                <template slot="content">
                                                    <p
                                                        style="
                                                            text-align: justify;
                                                            width: 350px;
                                                        "
                                                    >
                                                        Includes 50 lots/doors
                                                        per development project.
                                                        Additional lots/doors
                                                        are charged on a per
                                                        door basis starting at
                                                        $30 per door.
                                                    </p>
                                                    <div
                                                        class="
                                                            modal-list
                                                            d-flex
                                                            my-4
                                                        "
                                                    >
                                                        <ul class="p-0">
                                                            <li
                                                                class="
                                                                    modal-list-title
                                                                "
                                                            >
                                                                Door Fee
                                                            </li>
                                                            <li>First 50</li>
                                                            <li>51 – 100</li>
                                                            <li>101 – 150</li>
                                                            <li>151 – 200</li>
                                                            <li>201+</li>
                                                        </ul>
                                                        <ul>
                                                            <li
                                                                class="
                                                                    modal-list-title
                                                                "
                                                            >
                                                                Billed at
                                                            </li>
                                                            <li>$0</li>
                                                            <li>$30</li>
                                                            <li>$25</li>
                                                            <li>$20</li>
                                                            <li>$15</li>
                                                        </ul>
                                                    </div>
                                                    <p
                                                        style="
                                                            text-align: justify;
                                                            width: 350px;
                                                        "
                                                    >
                                                        Each project
                                                        subscription is
                                                        inclusive of 50
                                                        lots/doors. Thereafter,
                                                        a per door charge
                                                        applies. The charge is
                                                        based on the number of
                                                        lots/doors to be
                                                        processed and supported
                                                        in your development
                                                        project. The Door fee is
                                                        a one-time fee that is
                                                        over and above your
                                                        monthly subscription fee
                                                        and it is charged upon
                                                        initial project set up.
														Note: Currency rates apply.
                                                    </p>
                                                </template>
                                                {{
                                                    instance.productType ==
                                                    "highrise"
                                                        ? "DOOR"
                                                        : "LOT"
                                                }}
                                                FEES
                                                <a-icon
                                                    type="question-circle"
                                                    style="
                                                        font-size: 12px;
                                                        color: black;
                                                        margin-left: 5px;
                                                    "
                                                />
                                            </a-popover>
                                        </div>
                                        {{
                                            instance.productType == "highrise"
                                                ? `${instance.doors} doors`
                                                : `${instance.lots} lots`
                                        }}
                                        total
                                    </span>
                                    <span
                                        v-if="
                                            additional.name === 'Training Hours'
                                        "
                                    >
                                        <div class="text-primary">
                                            TRAINING HOUR BLOCKS
                                        </div>
                                        {{ additional.quantity }}
                                        Blocks ({{ additional.quantity * 4 }} Hours)
                                    </span>
                                </a-col>
                                <a-col :span="$mq == 'sm' ? 6 : 8">
                                    <b>
                                        ${{ additional.price && additional.price.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})
                                        }}{{
                                            additional.recurrence === "onetime"
                                                ? ""
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "/month"
                                                : "/year"
                                        }}
                                    </b>
                                    <div>
                                        ({{
                                            additional.recurrence === "onetime"
                                                ? "one time fee"
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "billed monthly"
                                                : "billed yearly"
                                        }})
                                    </div>
                                </a-col>
                                <a-col
                                    :span="$mq == 'sm' ? 4 : 6"
                                    style="text-align: right"
                                    class="text-black"
                                >
                                    <b>${{ additional.price && additional.price.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) }}</b>
                                </a-col>
                            </a-row>
                        </div>
                        <hr
                            v-if="$mq != 'sm'"
                            style="margin-left: -24px; margin-right: -24px"
                        />

                        <a-row v-if="$mq != 'sm'">
                            <a-col :span="$mq == 'sm' ? 6 : 2" />
	                        <a-col
	                            :span="$mq == 'sm' ? 6 : 14"
                                style="text-align: right"
                            >
                                <p>Subtotal before tax</p>
                                <p v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">Discount <span>({{ instancePriceDetails.discounts.map(d => d.name).join(", ") }})</span></p>
								<p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">Tax <span>({{ instancePriceDetails.tax.map(t => t.name).join(", ") }})</span></p>
                                <p>Have a coupon code?</p>
                                <p><b>Total Amount</b></p>
                                <p>Currency</p>
                            </a-col>
                            <a-col
                                :span="$mq == 'sm' ? 4 : 6"
                                style="text-align: right"
                            >
                                <p>${{ instancePriceDetails.subtotal && instancePriceDetails.subtotal.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2) }}</p>
                                <p class="text-red" v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">
                                    -${{
                                        instancePriceDetails.discounts && instancePriceDetails.discounts
                                            .reduce((acc, discount) => {
                                                return acc + discount.price;
                                            }, 0)
                                            .toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2)
                                    }}
                                </p>
                                <p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">
                                    ${{
                                        instancePriceDetails.tax && instancePriceDetails.tax.reduce(
                                            (acc, tax) => {
                                                return acc + tax.price;
                                            },
                                            0
                                        ).toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2)
                                    }}
                                </p>

                                <p>
                                    <span
                                        class="dF aC ml-3"
                                        style="
                                            height: 24px;
                                            justify-content: end;
                                        "
                                    >
                                        <a-form-item
                                            style="
                                                width: 160px;
                                                margin: 0 30px 0 0;
                                            "
                                            has-feedback
                                            :validate-status="validCouponCode"
                                        >
                                            <a-input
                                                id="couponCode"
                                                v-model="couponCode"
                                                size="small"
                                            />
                                        </a-form-item>
										<a-tooltip v-if="promoCodeDescription" overlayClassName="change-tooltip-color" class="mr-3">
											<template #title>
												{{ promoCodeDescription }}
											</template>
											<a-icon type="question-circle" style="font-size: 14px" />
										</a-tooltip>
                                        <span
                                            class="text-primary"
                                            @click="fetchPriceDetails"
                                            style="cursor: pointer"
                                        >
                                            Apply</span
                                        >
                                    </span>
                                </p>
                                <p>
                                    <b> ${{ instancePriceDetails.total && instancePriceDetails.total.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2) }} </b>
                                </p>
                                <p>{{instancePriceDetails.currency || "USD"}}</p>
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 2 : 1" />
                        </a-row>
                    </div>

                    <hr
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />
					<a-form-model-item
                        label="Select Payment Mode"
                        prop="paymentMode"
                    >
						<a-radio-group v-model="instance.paymentMode">
							<a-radio-button value="online">
								Online
							</a-radio-button>
							<a-radio-button value="offline">
								Offline
							</a-radio-button>
						</a-radio-group>
                    </a-form-model-item>
					<div v-if="instance.paymentMode === 'online'">
	                    <a-form
	                        class="profile mt-5"
	                        v-if="existingPaymentMethods.length"
	                    >
	                        <a-row v-if="$mq != 'sm'">
	                            <a-col :span="$mq == 'sm' ? 2 : 2" />
	                            <a-col :span="$mq == 'sm' ? 8 : 8">
	                                <strong> Credit Card Number </strong>
	                            </a-col>
	                            <a-col :span="$mq == 'sm' ? 8 : 8">
	                                <strong>Cardholder Name </strong>
	                            </a-col>
	                            <a-col :span="4">
	                                <strong>Expiry Date </strong></a-col
	                            >
	                        </a-row>
	                        <hr
	                            v-if="$mq != 'sm'"
	                            style="margin-left: -24px; margin-right: -24px"
	                        />
	                        <div
	                            v-for="card in existingPaymentMethods"
	                            :key="card.id"
	                        >
	                            <a-row>
	                                <a-col :span="$mq == 'sm' ? 2 : 1">
	                                    <a-radio
	                                        :value="card.id"
	                                        @change="onChange"
	                                        :checked="card.id === value"
	                                    />
	                                </a-col>
	                                <a-col :span="$mq == 'sm' ? 2 : 2">
	                                    <img
	                                        v-if="
	                                            card &&
	                                            card.details &&
	                                            card.details.ccBrand === 'visa'
	                                        "
	                                        alt="Visa"
	                                        src="@/assets/visa.svg"
	                                        width="50px"
	                                    />
	                                    <img
	                                        v-else-if="
	                                            card &&
	                                            card.details &&
	                                            card.details.ccBrand === 'mc'
	                                        "
	                                        alt="MasterCard"
	                                        src="@/assets/mc.svg"
	                                        width="50px"
	                                    />
	                                    <img
	                                        v-else-if="
	                                            card &&
	                                            card.details &&
	                                            card.details.ccBrand === 'amex'
	                                        "
	                                        alt="American Express"
	                                        src="@/assets/amex.svg"
	                                        width="50px"
	                                    />
	                                </a-col>
	                                <a-col :span="$mq == 'sm' ? 6 : 6">
	                                    {{ cardNumberDetails(card.details) }}
	                                </a-col>
	                                <a-col :span="$mq == 'sm' ? 6 : 8">
	                                    {{ card.details.cardName }}
	                                </a-col>
	                                <a-col :span="5">
	                                    {{ card.details.expiry }}
	                                </a-col>
	                                <a-col :span="$mq == 'sm' ? 2 : 1" class="dF">
	                                    <div
	                                        class="mr-4 edit-delete"
	                                        @click="editCard(card)"
	                                    >
											<a-icon type="edit" />
	                                    </div>
	                                    <div
	                                        class="edit-delete"
	                                        @click="deleteCard(card)"
	                                        style="color: var(--danger)"
	                                    >
											<a-icon type="delete" style="color:red" />
	                                    </div>
	                                </a-col>
	                            </a-row>
	                            <hr
	                                style="margin-left: -24px; margin-right: -24px"
	                            />
	                        </div>
	                    </a-form>
						<h5
	                        class="pl-3 pt-3"
	                        style="color: var(--orange); cursor: pointer"
	                        @click="resetModalData, (modal.show = true)"
	                    >
	                        <a-icon type="plus-circle" /> &nbsp;&nbsp;Add a payment
	                        method
	                    </h5>
					</div>

                    <a-modal
                        :footer="null"
                        :centered="true"
                        :visible="switchCardModal.visible"
                        :width="720"
                        @cancel="closeModal"
                    >
                        <h5 class="mb-3">Remove Payment Method</h5>
                        <div
                            class="text-dark mb-5"
                            v-if="Object.keys(switchCardModal.card).length != 0"
                        >
                            {{
                                `Choose a new payment method before removing ${
                                    switchCardModal.card.details.ccBrand ==
                                    "visa"
                                        ? "Visa"
                                        : switchCardModal.card.details
                                              .ccBrand == "mc"
                                        ? "Mastercard"
                                        : "American Express"
                                } ${getCardNumber(
                                    switchCardModal.card.details.cardNo
                                )}`
                            }}
                        </div>
                        <div
                            :style="paymentI == 0 ? 'margin-top:1rem' : ''"
                            style="display: block"
                            v-for="(payment, paymentI) in removeCardOption"
                            :key="payment.value + paymentI"
                            :value="payment.value"
                        >
                            <div
                                class="dF aC"
                                style="justify-content: space-between"
                            >
                                <div class="dF aC">
                                    <a-radio
                                        :checked="
                                            switchCardModal.option ==
                                            payment.value
                                        "
                                        @change="changeRadio"
                                        :value="payment.value"
                                    />
                                    <div>
                                        <div>{{ payment.label }}</div>
                                        <div
                                            @click="updateCard(payment.value)"
                                            v-if="
                                                paymentI !=
                                                removeCardOption.length - 1
                                            "
                                            style="
                                                color: var(--orange);
                                                cursor: pointer;
                                            "
                                        >
                                            Update card info
                                        </div>
                                    </div>
                                </div>
                                <em
                                    @click="updateCard(payment.value)"
                                    v-if="
                                        paymentI != removeCardOption.length - 1
                                    "
                                    style="
                                        color: var(--orange);
                                        cursor: pointer;
                                    "
                                    class="fe fe-edit-2"
                                />
                            </div>
                            <hr
                                v-if="paymentI != removeCardOption.length - 1"
                            />
                        </div>
                        <div class="mt-5 dF aC legals">
                            By continuing, you agree to the Bildhive&nbsp;
                            <a
                                href="https://admin.bildhive.dev/system/terms"
                                target="_"
                                >Terms of Service</a
                            >.
                        </div>
                        <div class="dF aC mt-5 dF jE">
                            <a-button class="mr-3" @click="closeModal"
                                >CANCEL</a-button
                            >
                            <a-button
                                :loading="switchCardModal.loading"
                                @click="removePay"
                                type="primary"
                                >SAVE AND REMOVE</a-button
                            >
                        </div>
                    </a-modal>

                    <BillingModal
                        v-if="modal.show"
                        :show="modal.show"
                        :load="modal.load"
                        :action="modal.action"
                        :modalTitle="modal.title"
                        :modalOkText="modal.okText"
                        :states="states"
                        :provinces="provinces"
                        :paymentMethodData="paymentMethod"
                        @close="resetModalData"
                        @cardPayment="preSubmit"
                    ></BillingModal>
                    <div
                        :class="$mq == 'sm' ? '' : 'dF'"
                        :style="
                            $mq == 'sm'
                                ? 'margin-top:20px'
                                : 'margin-top: 100px;'
                        "
                    >
                        <div
                            style="margin-top: 11px"
                            :style="$mq == 'sm' ? 'width:100%' : 'width:50%'"
                        >
                            By continuing, you agree to the Bildhive
                            <router-link
                                to="/system/terms#payment"
                                target="_blank"
                                class="text-med-gray"
                                >Payments Terms</router-link
                            >
                        </div>
                        <div
                            :class="$mq == 'sm' ? 'mt-3' : ''"
                            class="dF jE w-full"
                        >
                            <a-button-group style="margin-right: 20px">
                                <a-button
                                    style="width: 100px; margin-right: 20px"
                                    @click="prev"
                                    >BACK</a-button
                                >
                                <a-button
                                    :loading="creating"
                                    type="primary"
                                    :disabled="value == 0 && instance.paymentMode === 'online'"
                                    style="width: 100px"
                                    @click="submit"
                                    >CREATE</a-button
                                >
                            </a-button-group>
                        </div>
                    </div>
                </a-card>
            </div>
            <div v-else-if="successInInstanceCreation" class="action-result">
                <div class="text-center">
                    <img
                        width="100%"
                        height="100%"
                        src="@/assets/success.png"
                        alt="success"
                    />
                    <h2 class="mt-5">Project setup is complete</h2>

                    <div class="mt-3">
                        <a-button
                            type="primary"
                            style="width: 75%"
                            @click="redirectToHome"
                            >GET STARTED</a-button
                        >
                    </div>
                    <div class="mt-3">
                        <a-button style="width: 75%" @click="reset">
                            CREATE ANOTHER PROJECT</a-button
                        >
                    </div>
                </div>
            </div>
            <div v-else-if="errorInInstanceCreation" class="action-result">
                <div class="text-center">
                    <img
                        width="100%"
                        height="100%"
                        src="@/assets/error.png"
                        alt="error"
                    />
                    <h2>Something went wrong</h2>
                    <span class="mt-3" style="text-align: 'center'">
                        Something went wrong and Bildhive couldn’t set up your
                        project account. Please try again. If the problem
                        continues, Please contact
                        <span class="text-primary">support@bildhive.com </span>.
                    </span>

                    <div class="mt-3">
                        <a-button
                            type="primary"
                            style="width: 75%"
                            @click="errorInInstanceCreation = false"
                            >RETRY</a-button
                        >
                    </div>
                    <div class="mt-3">
                        <a-button style="width: 75%" @click="redirectToHome"
                            >CANCEL</a-button
                        >
                    </div>
                </div>
            </div>
        </div>
        <a-modal v-model="colorModal" title="Colors" @ok="handleAvatarChange">
            <div class="dF aC" style="flex-wrap: wrap">
                <div
                    class="px-1 py-1"
                    v-for="(color, colorI) in colourList"
                    :key="color + colorI"
                    :value="color"
                >
                    <div
                        class="relative"
                        @click="selectedColor = color"
                        :style="{ 'background-color': color }"
                        style="
                            border-radius: 50%;
                            height: 20px;
                            width: 20px;
                            cursor: pointer;
                        "
                    >
                        <div
                            v-if="selectedColor === color"
                            class="absolute"
                            style="
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <i class="fe fe-check" />
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import SelectProjectType from "@/components/common/SelectProjectType";
import { mapState, mapMutations } from "vuex";
import valid from "card-validator";
import BillingModal from "@/views/projects/billingmodal";
import postalCodes from "postal-codes-js";
import AllFeaturesList from "@/components/common/allFeaturesList";

const labelCol = {
    xs: { span: 24 },
    sm: { span: 4 },
};
const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 12 },
};

export default {
    components: { BillingModal, SelectProjectType, AllFeaturesList },
    data() {
        return this.initialState();
    },

    watch: {
        paymentMethod: {
            handler(newCard) {
                let { cardNo } = newCard;
                let nums = valid.number(cardNo);
                this.numberValidation = nums;
            },
            deep: true,
        },
        numberValidation: {
            handler(newCard) {
                let table = {
                    express: "amex",
                    mastercard: "mc",
                    visa: "visa",
                };
                if (
                    newCard.isPotentiallyValid &&
                    newCard.card &&
                    newCard.card.niceType
                ) {
                    let ccBrand = newCard.card.niceType.toLowerCase();
                    let foundKey = Object.keys(table).find((x) =>
                        ccBrand.includes(x)
                    );
                    if (foundKey) {
                        let filename = table[foundKey];
                        this.paymentMethod.ccBrand = filename;
                    } else {
                        this.paymentMethod.ccBrand = "";
                    }
                } else {
                    this.numberValidation.src = require(`@/assets/cc/error.png`);
                }
            },
            deep: true,
        },
    },

    computed: {
        ...mapState(["user", "allUsers", "promoCodes"]),

        packageStyle() {
            return {
                "grid-template-columns": `repeat(${this.packages.length}, minmax(0, 1fr))`,
                padding:
                    this.packages.length === 1
                        ? "0 33%"
                        : this.packages.length === 2
                        ? "0 16.5%"
                        : "0 0",
            };
        },

        ownedInstanceCount() {
            return this.user.user.ownedInstanceCount || 0;
        },

        addOnsPackages() {
            return this.packageExtras.filter((x) => x.type === "addon");
        },

        oneTimePackages() {
            return this.packageExtras.filter(
                (x) =>
                    x.type === "onetime" &&
                    x.identifier !== "lot_door_fee" &&
                    x.identifier !== "training_hours"
            );
        },

        removeCardOption() {
            let options = [];
            if (
                this.existingPaymentMethods &&
                this.existingPaymentMethods.length != 0 &&
                this.switchCardModal &&
                this.switchCardModal.card &&
                Object.keys(this.switchCardModal.card).length != 0
            ) {
                this.existingPaymentMethods.forEach((pay) => {
                    let obj = {};
                    if (pay.details.ccBrand == "visa") obj.name = "Visa";
                    else if (pay.details.ccBrand == "mc")
                        obj.name = "Mastercard";
                    else if (pay.details.ccBrand == "amex")
                        obj.name = "American Express";
                    obj.label = `${obj.name} ${this.getCardNumber(
                        pay.details.cardNo
                    )}`;
                    obj.value = pay.id;
                    if (obj.value != this.switchCardModal.card.id)
                        options.push(obj);
                });
                let newCard = {
                    label: "Add credit or debit card",
                    value: "newCard",
                };
                options.push(newCard);
            }
            return options;
        },

        websiteLink() {
            const tld = window.location.href.includes("bildhive.com")
                ? "com"
                : "dev";
            return `https://bildhive.${tld}/`;
        },

		promoCodeDescription() {
			if (this.couponCode && this.validCouponCode === "success") {
				const foundPC = this.promoCodes.find(p => p.code == this.couponCode)
				if (foundPC) return foundPC.description || ''
			}
			return ''
		}
    },

    async created() {
        this.fetchPaymentMethods();
		this.$store.dispatch('FETCH_ALL_PROMO_CODES');
        // this.getUsers();
    },

    methods: {
		...mapMutations(["SET_USERS"]),

        initialState() {
            return {
				tempProjectSearch: "",
				tempBillingSearch: "",
				projectUserSearchResult: [],
				billingUserSearchResult: [],
                creating: false,
                children: [],
                img: null,
                loading: false,
                imageUrl: "",
                form: this.$form.createForm(this),
                labelCol,
                wrapperCol,
                formData: null,
                instances: [],
				partners: [],
                instance: {
                    name: "",
					marketingName: "",
                    projectType: "",
                    productType: "lowrise",
					address: "",
					city: "",
					region: "",
					country: "CA",
					postalCode: "",
					email: "",
					avatar: "#FEC267",
					paymentMethod: "",
					lots: 0,
					doors: 0,
					builderName: "",
					companyName: "",
                    userId: "",
                    billingUserId: "",
                    billingName: "",
					partner: null,
					currency: "USD",
					paymentMode: "online"
                },
                couponCode: "",
                validCouponCode: "",
                current: 0,
                colorModal: false,
                selectedColor: "",
                steps: [
                    {
                        title: "Step 1",
                        description: "Provide Project Information",
                    },
                    {
                        title: "Step 2",
                        description: "Select your plan",
                    },
                    {
                        title: "Finish",
                        description: "How would you like to pay?",
                    },
                ],
                colourList: [
                    "#FEC267",
                    "#C1BCF4",
                    "#89C6E5",
                    "#7CDDC1",
                    "#FCAFBA",
                    "#3A558A",
                    "#CD4386",
                    "#E089B8",
                    "#EF6D8B",
                    "#FFDCE6",
                    "#227949",
                    "#9CB63E",
                    "#C7E77A",
                    "#18A1B1",
                    "#58CAD5",
                    "#F3AC46",
                    "#FED762",
                    "#0A5A93",
                    "#83776B",
                    "#BEB7AC",
                    "#EEDDBF",
                    "#F3F1EC",
                    "#AE9898",
                    "#5AF8E1",
                    "#52C4FD",
                    "#AB81FC",
                    "#C3C3C5",
                    "#FD395D",
                    "#ACECF9",
                    "#FECCAC",
                    "#D18063",
                    "#917B56",
                    "#FDA28F",
                    "#7FFD80",
                    "#AAD7CF",
                    "#D8ECE2",
                    "#CBCFE0",
                    "#7F7F7F",
                    "#B2B2B2",
                    "#E5E5E5",
                ],
                states: [
                    { name: "Alabama", value: "AL" },
                    { name: "Alaska", value: "AK" },
                    { name: "Arizona", value: "AZ" },
                    { name: "Arkansas", value: "AR" },
                    { name: "California", value: "CA" },
                    { name: "Colorado", value: "CO" },
                    { name: "Connecticut", value: "CT" },
                    { name: "Delaware", value: "DE" },
                    { name: "District of Columbia", value: "DC" },
                    { name: "Florida", value: "FL" },
                    { name: "Georgia", value: "GA" },
                    { name: "Hawaii", value: "HI" },
                    { name: "Idaho", value: "ID" },
                    { name: "Illinois", value: "IL" },
                    { name: "Indiana", value: "IN" },
                    { name: "Iowa", value: "IA" },
                    { name: "Kansas", value: "KS" },
                    { name: "Kentucky", value: "KY" },
                    { name: "Louisiana", value: "LA" },
                    { name: "Maine", value: "ME" },
                    { name: "Maryland", value: "MD" },
                    { name: "Massachusetts", value: "MA" },
                    { name: "Michigan", value: "MI" },
                    { name: "Minnesota", value: "MN" },
                    { name: "Mississippi", value: "MS" },
                    { name: "Missouri", value: "MO" },
                    { name: "Montana", value: "MT" },
                    { name: "Nebraska", value: "NE" },
                    { name: "Nevada", value: "NV" },
                    { name: "New Hampshire", value: "NH" },
                    { name: "New Jersey", value: "NJ" },
                    { name: "New Mexico", value: "NM" },
                    { name: "New York", value: "NY" },
                    { name: "North Carolina", value: "NC" },
                    { name: "North Dakota", value: "ND" },
                    { name: "Ohio", value: "OH" },
                    { name: "Oklahoma", value: "OK" },
                    { name: "Oregon", value: "OR" },
                    { name: "Pennsylvania", value: "PA" },
                    { name: "Rhode Island", value: "RI" },
                    { name: "South Carolina", value: "SC" },
                    { name: "South Dakota", value: "SD" },
                    { name: "Tennessee", value: "TN" },
                    { name: "Texas", value: "TX" },
                    { name: "Utah", value: "UT" },
                    { name: "Vermont", value: "VT" },
                    { name: "Virginia", value: "VA" },
                    { name: "Washington", value: "WA" },
                    { name: "West Virginia", value: "WV" },
                    { name: "Wisconsin", value: "WI" },
                    { name: "Wyoming", value: "WY" },
                ],
                provinces: [
                    { name: "Alberta", value: "AB" },
                    { name: "British Columbia", value: "BC" },
                    { name: "Manitoba", value: "MB" },
                    { name: "New Brunswick", value: "NB" },
                    { name: "Newfoundland and Labrador", value: "NL" },
                    { name: "Nova Scotia", value: "NS" },
                    { name: "Ontario", value: "ON" },
                    { name: "Prince Edward Island", value: "PE" },
                    { name: "Quebec", value: "QC" },
                    { name: "Saskatchewan", value: "SK" },
                    { name: "Northwest Territories", value: "NT" },
                    { name: "Nunavut", value: "NU" },
                    { name: "Yukon", value: "YT" },
                ],
                paymentMethod: {
                    ccBrand: "",
                    cardNo: "",
                    expiry: "",
                    cardName: "",
                    cvv: "",
					default: false,
                    address: {
                        one: "",
                        two: "",
                        region: "",
                        city: "",
                        country: "US",
                        zipcode: "",
                    },
                },
                numberValidation: {
                    card: {
                        niceType: "",
                        type: "",
                        patterns: [4],
                        gaps: [4, 8, 12],
                        lengths: [16, 18, 19],
                        code: { name: "CVV", size: 3 },
                        matchStrength: 1,
                    },
                    isPotentiallyValid: false,
                    isValid: false,
                },
                existingPaymentMethods: [],
                value: 0,
                modal: {
                    show: false,
                    load: false,
                    title: "Add New Card",
                    okText: "Add Card",
                    action: "add",
                    paymentMethodId: "",
                },
                switchCardModal: {
                    card: {},
                    visible: false,
                    option: "",
                    loading: false,
                },
                recurrence: "monthly",
                packages: [],
                packageExtras: [],
                instancePriceDetails: {},
                showAllFeatures: false,
                selectedPackageId: null,
                errorInInstanceCreation: false,
                successInInstanceCreation: false,
                selectedExtraPackages: {},
            };
        },

		handleProjectSearch(input) {
			console.log('Handle Search');
			if (input.length < 3) {
				return (this.timerId = undefined);
			}
			const makeAPICall = () => {
				this.updateProjectList(input);
			};

			if (this.timerId) clearTimeout(this.timerId);

			this.timerId = setTimeout(function () {
				makeAPICall();
				this.timerId = undefined;
			}, 400);
        },
		async updateProjectList(input) {
			try {
				let { data } = await this.$api.get(
					`/super-admin/users?_q=${input}&limited=true`
				);
				this.projectUserSearchResult = data.map(({ name, id, email }) => ({
					label: name,
					value: id,
					email: email,
				}));
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
        },
		handleBillingSearch(input) {
			console.log('Handle Search');
			if (input.length < 3) {
				return (this.timerId = undefined);
			}
			const makeAPICall = () => {
				this.updateBillingList(input);
			};

			if (this.timerId) clearTimeout(this.timerId);

			this.timerId = setTimeout(function () {
				makeAPICall();
				this.timerId = undefined;
			}, 400);
        },
		async updateBillingList(input) {
			try {
				let { data } = await this.$api.get(
					`/super-admin/users?_q=${input}&limited=true`
				);
				this.billingUserSearchResult = data.map(({ name, id, email }) => ({
					label: name,
					value: id,
					email: email,
				}));
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
        },

		onCurrencyChange() {
			this.fetchPackageDetails();
		},

        onCheckboxChange(event, extraPackage, packageId) {
            const obj = { ...extraPackage, selected: event.target.checked };

            const index = this.selectedExtraPackages[packageId].findIndex(
                (item) => item.id === obj.id
            );
            if (index === -1) {
                this.selectedExtraPackages[packageId].push(obj);
            } else {
                this.selectedExtraPackages[packageId][index] = obj;
            }
        },

        redirectToHome() {
            this.$router.push("/projects");
        },
        reset() {
            Object.assign(this.$data, this.initialState());
        },
        selectType(type) {
            this.instance.projectType = type;
        },
        req: (msg) => ({ required: true, message: msg }),
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
        cardNumberDetails(card) {
            if (card) {
                let cardNo = card.cardNo;
                let cardType = "";

                if (card.ccBrand === "visa") {
                    cardType = "Visa";
                } else if (card.ccBrand === "mc") {
                    cardType = "MasterCard";
                } else if (card.ccBrand === "amex") {
                    cardType = "AmericanExpress";
                }

                return (
                    cardType +
                    " ending in " +
                    cardNo.substring(cardNo.length - 4)
                );
            }
        },
        onChange(e) {
            this.value = e.target.value;
            this.instance.paymentMethod = e.target.value;
        },

        async fetchPaymentMethods() {
			try {
				let { data: method } = await this.$api.get("/payment-methods");
				this.existingPaymentMethods = Object.values(method);
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, `Error while fetching payment methods.`))
				}
			}
		},

        packageDetailsParams() {
            return {
                currency: this.instance.currency,
                packageId: this.selectedPackageId,
                recurrence: this.recurrence,
                promoCode: this.couponCode || "",
				partner: this.instance.partner,
				billingOwner: this.instance.billingUserId,
                country: this.instance.country,
                state: this.instance.region,
                packageExtras: this.selectedExtraPackages[
                    this.selectedPackageId
                ]
                    .map((extras) => {
                        if (
                            extras.selected ||
                            extras.identifier === "lot_door_fee"
                        ) {
                            const quantity =
                                this.instance.productType === "highrise"
                                    ? this.instance.doors
                                    : this.instance.lots;
                            return {
                                id: extras.id,
                                quantity: extras.selected ? 1 : quantity,
                            };
                        } else {
                            return null;
                        }
                    })
                    .filter((pe) => pe),
            };
        },

        fetchPriceDetails() {
            this.instanceParams = { ...this.packageDetailsParams() };
            this.validCouponCode = this.couponCode ? "validating" : "";
            this.$api
                .post("/instances/price", this.instanceParams)
                .then(({ data }) => {
                    this.instancePriceDetails = data;

                    if (this.couponCode) {
                        data.discounts.forEach((discount) => {
                            if (discount.code === this.couponCode) {
                                this.validCouponCode = "success";
                            }
                        });
                        if (this.validCouponCode === "validating") {
                            this.validCouponCode = "error";
                            this.$message.error("Invalid coupon code.");
                        } else if (this.validCouponCode === "success") {
                            this.$message.success(
                                "Coupon code applied successfully."
                            );
                        }
                    }
                })
                .catch((err) => {
                    if (err?.response?.data?.statusCode === 400) {
                        this.validCouponCode = this.couponCode ? "error" : "";
                    }
                });
        },

        next() {
            if (this.current === 1 && this.selectedPackageId) {
                this.fetchPriceDetails();
                this.current++;
                return;
            }
            this.$refs.instance.validate((validForm, invalidFields) => {
                if (validForm) {
                    this.current++;
                    if (this.current === 1) {
                        this.fetchPackageDetails()
                    }
                } else {
                    console.log("error submit!!", invalidFields);
                }
            });
        },

		fetchPackageDetails() {
			this.packages = [];
			this.packageExtras = [];

			const promises = [
				this.$api.get(
					`/packages?projectType=${this.instance.projectType}&productType=${this.instance.productType}&currency=${this.instance.currency}`
				),
				this.$api.get(`/package-extras?currency=${this.instance.currency}`),
			];

			Promise.all(promises)
				.then(([packages, packageExtras]) => {
					this.packages = packages.data;
					this.packageExtras = packageExtras.data;

					const defaultPackagesArray = [];

					this.packageExtras.forEach((pe) => {
						if (pe.identifier === "lot_door_fee") {
							defaultPackagesArray.push(pe);
						} else if (
							pe.identifier === "onboarding" &&
							this.ownedInstanceCount === 0
						) {
							defaultPackagesArray.push({
								...pe,
								selected: true,
							});
						}
					});

					this.packages.forEach((p) => {
						this.selectedExtraPackages[p.id] =
							defaultPackagesArray;
					});
					this.packages.sort((a, b) => b.price - a.price);
				})
				.catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, `Error while fetching packages. Please try again!`));
					}
				});
		},

        prev() {
            if (this.current === 1) {
                this.packages = [];
                this.packageExtras = [];
            }
            this.current--;
        },

        resetModalData() {
            this.modal.action = "add";
            this.modal.okText = "Add Card";
            this.modal.title = "Add New Card";
            this.modal.paymentMethodId = "item.id";
            this.modal.show = false;
            this.modal.load = false;
            this.paymentMethod = {
                ccBrand: "",
                cardNo: "",
                expiry: "",
                cardName: "",
                cvv: "",
				default: false,
                address: {
                    one: "",
                    two: "",
                    region: "",
                    city: "",
                    country: "US",
                    zipcode: "",
                },
            };
        },
        preSubmit(data) {
            this.paymentMethod = data;

            let expiry = valid.expirationDate(this.paymentMethod.expiry);
            let sendCardOBJ = JSON.parse(JSON.stringify(this.paymentMethod));
            let { month, year } = expiry;
            sendCardOBJ.expiry = `${month}/${year}`;
            let self = this;
            this.modal.load = true;
            if (this.modal.action === "add") {
                this.$api
                    .post("/payment-methods", {
                        details: sendCardOBJ,
                    })
                    .then(async ({ data }) => {
                        if (data.success === false) {
                            let errorMessage = data.result.message;
                            errorMessage = errorMessage.split("\n");
                            errorMessage.forEach((mess) => {
                                self.$message.error(mess);
                            });
                        } else {
							this.$message.success('Payment method added successfully!');
                            self.existingPaymentMethods.push(data);
                        }
                        await this.fetchPaymentMethods();
                        this.resetModalData();
                    })
                    .catch((err) => {
                        self.modal.load = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `Error while creating payment method. Try Again!`));
						}
                    });
            } else if (this.modal.action === "edit") {
                this.$api
                    .put(`/payment-methods/${this.modal.paymentMethodId}`, {
                        details: sendCardOBJ,
                    })
                    .then(async ({ data }) => {
                        if (data.success == false) {
                            let errorMessage = data.result.message;
                            errorMessage = errorMessage.split("\n");
                            errorMessage.forEach((mess) => {
                                self.$message.error(mess);
                            });
                        } else {
                            self.existingPaymentMethods.forEach((pm, index) => {
                                if (pm.id === data.id) {
                                    self.existingPaymentMethods[index] = data;
                                }
                            });
                        }
                        this.resetModalData();
                        await this.fetchPaymentMethods();
                    })
                    .catch((err) => {
                        self.modal.load = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `Error while updating payment method. Try Again!`));
						}
                    });
            }
        },
        async submit() {
            let formData = this.formData;
            let avatarUrl = false;
            if (formData) {
                let okay = false;
                for (var [key, value] of formData.entries()) {
                    if (value) okay = true;
                }
                if (okay) {
                    let url = `/upload`;
                    let { data } = await this.$api.post(url, formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    });

                    if (Array.isArray(data) && data.length) {
                        data = data[0];
                        if (data.url) {
                            avatarUrl = data.url;
                        }
                    }
                }
            }
            let sendObj = JSON.parse(JSON.stringify(this.instance));
            if (
                this.instances &&
                this.instances.length &&
                this.children.length
            ) {
                sendObj.children = this.children;
            }

            sendObj = {
                ...sendObj,
                ...this.packageDetailsParams(),
            };

            if (avatarUrl) sendObj.avatar = avatarUrl;
            let url = `/instances`;
            this.creating = true;
            this.$api
                .post(url, sendObj)
                .then(({ data }) => {
                    if (data.id) {
                        this.creating = false;
                        this.formData = null;
                        this.$notification["success"]({
                            message: "Success",
                            description:
                                "Project created successfully!",
                            duration: 3,
                        });
                        this.$store.commit("CREATE_UPDATE", sendObj);
						this.redirectToHome();
                    }
                })
                .catch((err) => {
                    this.creating = false;
					this.errorInInstanceCreation = true;
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, `Error while creating Project. Please Try Again!`));
					}
                });
        },
        handleAvatarChange() {
            this.colorModal = false;
            this.instance.avatar = this.selectedColor;
        },

        validatePostalCodes(rule, value, callback) {
            if (value === "") {
                callback(new Error("Please enter a Zip/Postal Code"));
            } else {
                if (!this.instance.country) {
                    callback(new Error("Please select a country first"));
                }
                const countryCode = this.instance.country;
                const result = postalCodes.validate(countryCode, value);
                if (result === true) {
                    callback();
                } else {
                    callback(new Error(result));
                }
            }
        },

        changeRadio(e) {
            this.switchCardModal.option = e.target.value;
        },

        getCardNumber(string) {
            let card = string.substring(string.length - 4, string.length);
            return `****${card}`;
        },

        updateCard(id) {
            let found = this.existingPaymentMethods.find((x) => x.id == id);
            if (found) {
                this.editCard(found);
            }
        },
        removePay() {
            if (this.switchCardModal.option == "")
                return this.$message.error("Please select an option.");
            else if (this.switchCardModal.option == "newCard") {
                this.resetModalData();
                this.modal.show = true;
            } else {
                this.switchCardModal.loading = true;
                this.$api
                    .put(
                        `/payment-methods/${this.switchCardModal.card.id}/switch`,
                        { id: this.switchCardModal.option }
                    )
                    .then(({ data }) => {
                        let index = this.existingPaymentMethods.findIndex(
                            (x) => x.id == data.id
                        );
                        if (index != -1)
                            this.existingPaymentMethods.splice(index, 1);
                        this.switchCardModal.loading = false;
                        this.closeModal();
                    });
            }
        },

        editCard(item) {
            this.modal.action = "edit";
            this.modal.okText = "Update";
            this.modal.title = "Edit Card Details";
            this.modal.paymentMethodId = item.id;
            this.modal.show = true;

            this.paymentMethod.ccBrand = item.details.ccBrand;
            this.paymentMethod.cardNo = item.details.cardNo;
            this.paymentMethod.expiry = item.details.expiry;
            this.paymentMethod.cardName = item.details.cardName;
            this.paymentMethod.cvv = item.details.cvv;
            this.paymentMethod.address.one = item.details.address.one;
            this.paymentMethod.address.two = item.details.address.two;
            this.paymentMethod.address.region = item.details.address.region;
            this.paymentMethod.address.city = item.details.address.city;
            this.paymentMethod.address.country = item.details.address.country;
            this.paymentMethod.address.zipcode = item.details.address.zipcode;
        },

        closeModal() {
            this.switchCardModal.visible = false;
            this.switchCardModal.card = {};
            this.switchCardModal.option = "";
        },

        deleteCard(card) {
            if (card.instances && card.instances.length > 0) {
                this.switchCardModal.visible = true;
                this.switchCardModal.card = card;
            } else if (!card.instances || card.instances.length == 0) {
                let self = this;
                this.$confirm({
                    title: "Delete Payment Method",
                    content: (h) => <div>Do you want to delete this card?</div>,
                    okText: "DELETE",
                    okType: "danger",
                    cancelText: "CANCEL",
                    centered: true,
                    onOk() {
                        self.confirmDelete(card);
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            }
        },
        confirmDelete(item) {
            this.$api
                .delete(`/payment-methods/${item.id}`)
                .then(({ data }) => {
                    this.existingPaymentMethods =
                        this.existingPaymentMethods.filter(
                            (x) => x.id !== data.id
                        );
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, `Error while deleting payment method. Please Try Again!`));
					}
                });
        },

        async getUserInstances() {
            if (this.instance.userId && this.instance.projectType !== 'solo') {
                try {
                    let { data } = await this.$api.get(
                        `/super-admin/instances?_limit=-1&_where[_or][0][owner]=${this.instance.userId}&_where[_or][1][0][users]=${this.instance.userId}&_where[_or][1][1][users.role.name]=bh_admin`
                    );
                    this.instances = data;
                } catch (err) {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while fetching members list. Please try again!"))
					}
                }
            }
        },

		async getPartners() {
            this.loading = true;
			this.instance.partner = null;
			this.partners = [];
            try {
                let { data } = await this.$api.get(`/partners?users=${this.instance.billingUserId}`);
				this.partners = data;
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching partner list. Please try again!"))
				}
            }
            this.loading = false;
        },

        // async getUsers() {
        //     this.loading = true;
        //     try {
        //         let { data } = await this.$api.get(`/super-admin/users`);
		// 		this.$store.commit("SET_USERS", data.filter(u => u.email).sort((a, b) => a.email.localeCompare(b.email)));
        //     } catch (error) {
        //         console.error("Error while fetching user list", error);
        //     }
        //     this.loading = false;
        // },
    },
};
</script>

<style scoped>
.image-holder-box {
    position: absolute;
    width: 76px;
    height: 76px;
    left: 50%;
    margin-left: -38px;
    z-index: 1000;
    top: 50%;
    margin-top: -38px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
}
.edit-current-image {
    position: absolute;
    top: 25px;
    z-index: 100;
    right: -27px;
}
.edit-current-image:hover {
    cursor: pointer;
}
.hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
    cursor: pointer;
}
.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon-in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    background-color: #d0c9d6;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}
.hexagon2 {
    width: 100px;
    height: 200px;
    margin: -60px 0 0 10px;
}
</style>

<style lang="scss">
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
.modal-list {
    .modal-list-title {
        color: #40454c;
        font-size: clamp(16px, 2vw, 18px);
        line-height: clamp(20px, 2vw, 24px);
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
    }
    ul {
        list-style: none;
    }
}

.legals {
    a {
        color: var(--primary);
    }
}

.ant-steps {
    width: 99%;
}
.ant-form-item-label {
    > label::after {
        content: "";
    }
}
.ant-btn-group {
    > .ant-btn {
        border-radius: 4px !important;
    }
}
.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item-description {
    max-width: 175px;
}

.grid-display {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.75rem;
}
.type-card {
    border: 2px solid #d0c9d6;
    box-shadow: #e1dede 1px 1px 5px;
    border-radius: 4px;
    cursor: pointer;
}
.type-card:hover {
    border-color: var(--orange);
}

.selected-package {
    border: 2px solid var(--orange);
    border-radius: 4px;
}

.ant-radio-button-wrapper-checked {
    color: var(--black) !important;
    border: 2px solid var(--orange) !important;
    border-radius: 4px;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    background-color: var(--light) !important;
}

.ant-radio-group-large .ant-radio-button-wrapper {
    line-height: 2;
}

.ant-tooltip .ant-tooltip-inner {
    background: #3f3455;
}

.checkbox-label {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin: 0 0 0 25px;
}

.feature-title {
    font-size: 18px;
    color: var(--orange);
    text-align: left;
    margin: 20px 0 0 0;
}

.ant-list-item {
    padding: 10px 0 0 0;
    border-bottom: 0px !important;
}

.action-result {
    width: 350px;
    margin: 10px auto;
}

.edit-delete {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    transition: color 0.1s ease-in;
}
</style>
